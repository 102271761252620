import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import remark from 'remark'
import remarkHTML from 'remark-html'
import Banner from "../components/banner"

import withPatient from "../assets/yoga-therapy/anne-pitman-works-with-patient-julie-truelove-at-the-ottawa.jpg"
import buddha from "../assets/yoga-therapy/buddha-statue.jpg"
import annesHand from "../assets/yoga-therapy/annes-hand.jpg"

import seedsIcon from "../assets/yoga-therapy/seeds-icon.svg"

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark

  const bannerUrl = "/yoga-therapy/yoga-therapy-banner.jpg";

  return (
    <Layout title={frontmatter.title} description="An outline of what yoga therapy is, what to expect in a yoga therapy session, details about yoga therapy and cancer, and yoga therapy and injury/pain.">
      <Banner title={frontmatter.title} imageUrl={bannerUrl} />

      <section className="section has-background-secondary-faded has-text-centered">
        <div className="container" style={{position: "relative"}}>     
          <h3 className="has-text-secondary title is-3" style={{maxWidth:"900px", marginLeft: "auto", marginRight: "auto"}}>
            {frontmatter.ctaText}        
          </h3>
          <Link className="button is-secondary wide transition" to="/contact">GET IN TOUCH</Link>
        </div>
      </section>

      <section className="section">
        <div className="container content">
          <h2 className="title is-2 has-text-tertiary">{frontmatter.section1Header}</h2>
          <div className="columns">
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.section1text)}} />
            </div>
            <div className="column">
              <img src={buddha} alt="Buddha statue" />
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-tertiary-faded">
        <div className="container">
          <h2 className="title is-2 has-text-tertiary">{frontmatter.section2Header}</h2>
          <div className="columns">
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.section2text)}} />
            </div>
            <div className="column">
              <img src={annesHand} alt="Anne's hand" />
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-secondary-faded has-text-centered">
        <div className="container" style={{position: "relative"}}>     
          <h3 className="has-text-secondary title is-3" style={{maxWidth:"900px", marginLeft: "auto", marginRight: "auto"}}>
            {frontmatter.quote2}<br /><br />
            - {frontmatter.quote2Author}         
          </h3>
        </div>
      </section>

      <section className="section">
        <div className="container content">
          <h2 className="title is-2 has-text-tertiary">{frontmatter.section3Header}</h2>
          <div className="columns is-multiline">
            <div className="column is-half">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.section3text1)}} />
            </div>
            <div className="column is-half">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.section3text2)}} />
            </div>
            <div className="column is-half">
              <img src={withPatient} alt="Anne with a patient" />
            </div>
            <div className="column is-half">
              <div className="block" style={{position: "relative", marginBottom: "60px"}}> 
                <div className="archway-container">
                  <img src={seedsIcon} alt="Archway" style={{width: "100%", height: "100%", opacity:"0.2"}} />
                </div>     
                <h2 className="has-text-secondary has-text-centered title is-2" style={{maxWidth: "900px", margin: "auto", position:"relative", zIndex:"3"}}>
                  {frontmatter.quote}       
                </h2>
              </div>
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.section3text3)}} />
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-secondary-faded has-text-centered">
        <div className="container" style={{position: "relative"}}>     
          <h3 className="has-text-secondary title is-4" style={{maxWidth:"900px", marginLeft: "auto", marginRight: "auto", lineHeight: "40px"}}>
            {frontmatter.quote3}<br /><br />
            - {frontmatter.quote3Author}         
          </h3>
        </div>
      </section>

      <section className="section has-background-tertiary-faded">
        <div className="container content">
          <h2 className="title is-2 has-text-tertiary">{frontmatter.section4Header}</h2>
          <div className="columns is-multiline">
            <div className="column is-half">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.section4text1)}} />
            </div>
            <div className="column is-half">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.section4text2)}} />
            </div>
            <div className="column is-half">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.section4text3)}} />
            </div>
            <div className="column is-half">

            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        section1Header
        section1text
        section2Header
        section2text
        section3Header
        section3text1
        section3text2
        section3text3
        section4Header
        section4text1
        section4text2
        section4text3
        quote
        ctaText
        quote2
        quote2Author
        quote3
        quote3Author
      }
    }
  }
`